import React from 'react';
import { Box, Typography } from '@mui/material';

interface LogoProps {
  showText?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const Logo: React.FC<LogoProps> = ({ showText = true, size = 'medium' }) => {
  const logoSizes = {
    small: 30,
    medium: 40,
    large: 60
  };

  const textSizes = {
    small: '1rem',
    medium: '1.5rem',
    large: '2rem'
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <img 
        src="/pixuate.svg"
        alt="Pixuate Logo"
        style={{ 
          height: logoSizes[size],
          width: 'auto',
          filter: 'brightness(0) invert(1)' // Make SVG white for dark theme
        }}
      />
      {showText && (
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: textSizes[size],
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '0.02em'
          }}
        >
        </Typography>
      )}
    </Box>
  );
};

export default Logo;
