import React, { useState, useCallback } from 'react';
import { 
  Box, 
  Button, 
  Paper, 
  Typography, 
  CircularProgress, 
  IconButton,
  Tooltip,
  Alert,
  LinearProgress,
  Card,
  CardContent,
  Fade,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useDropzone } from 'react-dropzone';
import { cattleService } from '../services/cattle';

const DropzoneArea = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    borderColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.action.hover,
  },
}));

const ImagePreviewCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  '&:hover .image-actions': {
    opacity: 1,
  },
}));

const ImageActions = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: theme.spacing(1),
  opacity: 0,
  transition: 'opacity 0.3s ease',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
}));

interface MatchResult {
  message: string;
  score?: number;
  processingTime?: number;
}

const MuzzleMatching: React.FC = () => {
  const [image1, setImage1] = useState<File | null>(null);
  const [image2, setImage2] = useState<File | null>(null);
  const [preview1, setPreview1] = useState<string>('');
  const [preview2, setPreview2] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState<MatchResult | null>(null);

  const onDrop1 = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0];
      setImage1(file);
      setPreview1(URL.createObjectURL(file));
    }
  }, []);

  const onDrop2 = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0];
      setImage2(file);
      setPreview2(URL.createObjectURL(file));
    }
  }, []);

  const { getRootProps: getRootProps1, getInputProps: getInputProps1, isDragActive: isDragActive1 } = useDropzone({
    onDrop: onDrop1,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    maxSize: 15242880,
    multiple: false,
  });

  const { getRootProps: getRootProps2, getInputProps: getInputProps2, isDragActive: isDragActive2 } = useDropzone({
    onDrop: onDrop2,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    maxSize: 15242880,
    multiple: false,
  });

  const handleDelete = (imageNumber: 1 | 2) => {
    if (imageNumber === 1) {
      setImage1(null);
      setPreview1('');
    } else {
      setImage2(null);
      setPreview2('');
    }
    setResult(null);
  };

  const handleSubmit = async () => {
    if (!image1 || !image2) {
      return;
    }

    setLoading(true);
    setProgress(0);
    setResult(null);

    try {
      // Simulate progress
      const progressInterval = setInterval(() => {
        setProgress(prev => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return 90;
          }
          return prev + 10;
        });
      }, 200);

      const response = await cattleService.matchTwoImages(image1, image2);
      
      clearInterval(progressInterval);
      setProgress(100);
      
      setResult({
        message: response.message,
        score: response.similarity_score,
        processingTime: response.processing_time
      });
    } catch (error: any) {
      console.error('Error:', error);
      setResult({ 
        message: error.response?.data?.error || 'An error occurred while processing the images'
      });
    } finally {
      setLoading(false);
    }
  };

  const getScoreColor = (score: number) => {
    if (score >= 0.8) return 'success.main';
    if (score >= 0.6) return 'warning.main';
    return 'error.main';
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          Muzzle Pattern Matching
          <Tooltip title="Upload two muzzle images to check if they belong to the same cattle">
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Our advanced AI system will analyze and compare two muzzle patterns to determine if they match.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: 3, flexDirection: { xs: 'column', md: 'row' } }}>
        <Paper sx={{ flex: 1, p: 3, borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium' }}>
            First Muzzle Image
          </Typography>
          <DropzoneArea
            {...getRootProps1()}
            sx={{
              borderColor: isDragActive1 ? 'primary.main' : 'divider',
              bgcolor: isDragActive1 ? 'action.hover' : 'background.paper',
            }}
          >
            <input {...getInputProps1()} />
            <CloudUploadIcon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }} />
            <Typography variant="subtitle1" gutterBottom>
              {isDragActive1 ? 'Drop the image here' : 'Drag and drop an image here'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Supported formats: JPEG, PNG (max 15MB)
            </Typography>
          </DropzoneArea>

          {preview1 && (
            <Fade in timeout={500}>
              <ImagePreviewCard sx={{ mt: 2 }}>
                <Box
                  component="img"
                  src={preview1}
                  alt="First muzzle"
                  sx={{
                    width: '100%',
                    height: 250,
                    objectFit: 'contain',
                    bgcolor: 'black',
                  }}
                />
                <ImageActions className="image-actions">
                  <IconButton
                    size="small"
                    onClick={() => handleDelete(1)}
                    sx={{ color: 'white' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ImageActions>
              </ImagePreviewCard>
            </Fade>
          )}
        </Paper>

        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            p: 2
          }}
        >
          <CompareArrowsIcon 
            sx={{ 
              fontSize: 40,
              color: 'primary.main',
              transform: { xs: 'rotate(90deg)', md: 'none' }
            }} 
          />
        </Box>

        <Paper sx={{ flex: 1, p: 3, borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium' }}>
            Second Muzzle Image
          </Typography>
          <DropzoneArea
            {...getRootProps2()}
            sx={{
              borderColor: isDragActive2 ? 'primary.main' : 'divider',
              bgcolor: isDragActive2 ? 'action.hover' : 'background.paper',
            }}
          >
            <input {...getInputProps2()} />
            <CloudUploadIcon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }} />
            <Typography variant="subtitle1" gutterBottom>
              {isDragActive2 ? 'Drop the image here' : 'Drag and drop an image here'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Supported formats: JPEG, PNG (max 15MB)
            </Typography>
          </DropzoneArea>

          {preview2 && (
            <Fade in timeout={500}>
              <ImagePreviewCard sx={{ mt: 2 }}>
                <Box
                  component="img"
                  src={preview2}
                  alt="Second muzzle"
                  sx={{
                    width: '100%',
                    height: 250,
                    objectFit: 'contain',
                    bgcolor: 'black',
                  }}
                />
                <ImageActions className="image-actions">
                  <IconButton
                    size="small"
                    onClick={() => handleDelete(2)}
                    sx={{ color: 'white' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ImageActions>
              </ImagePreviewCard>
            </Fade>
          )}
        </Paper>
      </Box>

      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!image1 || !image2 || loading}
          size="large"
          sx={{
            minWidth: 250,
            py: 1.5,
            px: 4,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '1.1rem',
          }}
        >
          {loading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={24} color="inherit" />
              Processing...
            </Box>
          ) : (
            'Compare Muzzles'
          )}
        </Button>

        {loading && (
          <Box sx={{ width: '100%', maxWidth: 400, mt: 3 }}>
            <LinearProgress 
              variant="determinate" 
              value={progress} 
              sx={{ height: 8, borderRadius: 4 }}
            />
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
              {progress}% Complete
            </Typography>
          </Box>
        )}

        {result && (
          <Fade in timeout={500}>
            <Paper 
              sx={{ 
                mt: 4, 
                p: 3, 
                width: '100%',
                maxWidth: 600,
                borderRadius: 2,
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Matching Results
              </Typography>
              
              <Alert 
                severity={result.score && result.score >= 0.8 ? "success" : "error"}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                {result.message}
              </Alert>

              {result.score !== undefined && (
                <>
                  <Box sx={{ mt: 3, mb: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Similarity Score
                    </Typography>
                    <Typography 
                      variant="h4" 
                      sx={{ 
                        color: getScoreColor(result.score),
                        fontWeight: 'bold'
                      }}
                    >
                      {(result.score * 100).toFixed(1)}%
                    </Typography>
                  </Box>
                  
                  <Divider sx={{ my: 2 }} />
                  
                  {result.processingTime && (
                    <Typography variant="body2" color="text.secondary">
                      Processing Time: {result.processingTime}ms
                    </Typography>
                  )}
                </>
              )}
            </Paper>
          </Fade>
        )}
      </Box>
    </Box>
  );
};

export default MuzzleMatching;
