import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { store } from './store/store';
import { ThemeProvider } from './theme/ThemeContext';
import Login from './pages/Login';
import AppLayout from './components/Layout/AppLayout';
import MuzzleDetection from './pages/MuzzleDetection';
import Dashboard from './pages/Dashboard';
import CattleMuzzleMatch from './pages/CattleMuzzleMatch';
import MuzzleMatching from './pages/MuzzleMatching';
import Statistics from './pages/Statistics';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';

// Protected Route component
const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <CssBaseline />
        <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <Dashboard />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/muzzle-detection"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <MuzzleDetection />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/cattle-muzzle-match"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <CattleMuzzleMatch />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/muzzle-matching"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <MuzzleMatching />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/statistics"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <Statistics />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
