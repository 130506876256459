import React, { useState, useCallback } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Alert,
  Fade,
  LinearProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { cattleService } from '../services/cattle';
import { useDropzone } from 'react-dropzone';

const DropzoneArea = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    borderColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.action.hover,
  },
}));

const ImagePreviewCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  '&:hover .image-actions': {
    opacity: 1,
  },
}));

const ImageActions = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: theme.spacing(1),
  opacity: 0,
  transition: 'opacity 0.3s ease',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
}));

interface DetectionResult {
  message: string;
  muzzle_image?: string;
  error?: string;
}

const MuzzleDetection: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [result, setResult] = useState<DetectionResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setResult(null);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    maxSize: 15242880, // 5MB
    multiple: false,
  });

  const handleDelete = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setResult(null);
    setUploadProgress(0);
  };

  const handleSubmit = async () => {
    if (!selectedFile) return;

    setLoading(true);
    setUploadProgress(0);
    
    try {
      // Simulate upload progress
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return 90;
          }
          return prev + 10;
        });
      }, 200);

      const response = await cattleService.detectMuzzle(selectedFile);
      
      clearInterval(progressInterval);
      setUploadProgress(100);
      
      setResult({
        message: response.message,
        muzzle_image: response.muzzle_image
      });
    } catch (error: any) {
      console.error('Error detecting muzzle:', error);
      setResult({
        error: error.response?.data?.error || 'An error occurred while processing the image',
        message: 'Detection failed'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          Muzzle Detection
          <Tooltip title="Upload a clear image of a cattle's face to detect and extract the muzzle pattern">
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Our AI system will automatically detect and extract the muzzle pattern from your cattle image.
        </Typography>
      </Box>

      <Paper sx={{ p: { xs: 2, sm: 3 }, mb: 3, borderRadius: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium' }}>
              Upload Image
            </Typography>
            <DropzoneArea
              {...getRootProps()}
              sx={{
                borderColor: isDragActive ? 'primary.main' : 'divider',
                bgcolor: isDragActive ? 'action.hover' : 'background.paper',
              }}
            >
              <input {...getInputProps()} />
              <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
              <Typography variant="subtitle1" gutterBottom>
                {isDragActive
                  ? 'Drop the image here'
                  : 'Drag and drop an image here, or click to select'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Supported formats: JPEG, PNG (max 15MB)
              </Typography>
            </DropzoneArea>

            {previewUrl && (
              <Fade in timeout={500}>
                <ImagePreviewCard sx={{ mt: 3 }}>
                  <CardContent sx={{ pb: 1 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Original Image
                    </Typography>
                  </CardContent>
                  <CardMedia
                    component="img"
                    image={previewUrl}
                    alt="Selected image"
                    sx={{ 
                      height: 300,
                      objectFit: 'contain',
                      bgcolor: 'black',
                    }}
                  />
                  <ImageActions className="image-actions">
                    <IconButton 
                      size="small" 
                      onClick={handleDelete}
                      sx={{ color: 'white' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ImageActions>
                </ImagePreviewCard>
              </Fade>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium' }}>
                Detection Result
              </Typography>
              
              {!selectedFile && !result && (
                <Box 
                  sx={{ 
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'action.hover',
                    borderRadius: 2,
                    p: 3,
                  }}
                >
                  <Typography variant="body1" color="text.secondary" align="center">
                    Upload an image to see the detection result
                  </Typography>
                </Box>
              )}

              {selectedFile && (
                <Box sx={{ mt: 2, mb: 3 }}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                    fullWidth
                    size="large"
                    sx={{
                      py: 1.5,
                      borderRadius: 2,
                      textTransform: 'none',
                      fontSize: '1.1rem',
                    }}
                  >
                    {loading ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircularProgress size={24} color="inherit" />
                        Processing...
                      </Box>
                    ) : (
                      'Detect Muzzle'
                    )}
                  </Button>
                </Box>
              )}

              {loading && (
                <Box sx={{ width: '100%', mb: 2 }}>
                  <LinearProgress 
                    variant="determinate" 
                    value={uploadProgress} 
                    sx={{ height: 8, borderRadius: 4 }}
                  />
                  <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                    {uploadProgress}% Complete
                  </Typography>
                </Box>
              )}

              {result && (
                <Fade in timeout={500}>
                  <Box>
                    {result.error ? (
                      <Alert 
                        severity="error"
                        variant="outlined"
                        sx={{ mb: 2 }}
                      >
                        {result.error}
                      </Alert>
                    ) : (
                      <>
                        <Alert 
                          severity="success"
                          variant="outlined"
                          sx={{ mb: 2 }}
                        >
                          {result.message}
                        </Alert>
                        {result.muzzle_image && (
                          <ImagePreviewCard>
                            <CardContent sx={{ pb: 1 }}>
                              <Typography variant="subtitle1" gutterBottom>
                                Detected Muzzle Pattern
                              </Typography>
                            </CardContent>
                            <CardMedia
                              component="img"
                              image={`data:image/jpeg;base64,${result.muzzle_image}`}
                              alt="Detected muzzle"
                              sx={{ 
                                height: 300,
                                objectFit: 'contain',
                                bgcolor: 'black',
                              }}
                            />
                          </ImagePreviewCard>
                        )}
                      </>
                    )}
                  </Box>
                </Fade>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default MuzzleDetection;
