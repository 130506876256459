import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  CircularProgress, 
  Grid,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Fade,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Skeleton,
} from '@mui/material';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip as ChartTooltip, 
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from 'chart.js';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import RefreshIcon from '@mui/icons-material/Refresh';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import InfoIcon from '@mui/icons-material/Info';
import api, { endpoints } from '../services/apiConfig';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  ChartTooltip,
  Legend
);

interface ApiStat {
  endpoint: string;
  count: number;
}

type ChartType = 'bar' | 'line' | 'doughnut';

const Statistics: React.FC = () => {
  const [stats, setStats] = useState<ApiStat[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [chartType, setChartType] = useState<ChartType>('bar');
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    setRefreshing(true);
    try {
      const response = await api.get<ApiStat[]>(endpoints.system.stats);
      setStats(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching statistics:', err);
      setError('Failed to load statistics');
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const getChartData = () => {
    const labels = stats.map(stat => stat.endpoint);
    const data = stats.map(stat => stat.count);
    const baseColors = [
      'rgba(54, 162, 235, 0.6)',
      'rgba(75, 192, 192, 0.6)',
      'rgba(255, 99, 132, 0.6)',
      'rgba(255, 206, 86, 0.6)',
      'rgba(153, 102, 255, 0.6)',
    ];

    const borderColors = baseColors.map(color => color.replace('0.6', '1'));
    const backgroundColors = baseColors.map((color, index) => {
      return chartType === 'line' ? borderColors[index] : color;
    });

    return {
      labels,
      datasets: [
        {
          label: 'API Calls',
          data,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: chartType === 'line' ? 2 : 1,
          tension: 0.4,
          fill: chartType === 'line' ? false : true,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          padding: 20,
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: true,
        text: 'API Usage Statistics',
        font: {
          size: 16,
          weight: 'bold' as const,
        },
        padding: 20,
      },
    },
    scales: chartType !== 'doughnut' ? {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          font: {
            size: 12,
          },
        },
        grid: {
          display: true,
          drawBorder: false,
        },
      },
      x: {
        ticks: {
          font: {
            size: 12,
          },
        },
        grid: {
          display: false,
        },
      },
    } : undefined,
    animation: {
      duration: 750,
    },
  };

  const getTotalCalls = () => stats.reduce((acc, stat) => acc + stat.count, 0);
  const getMostUsedEndpoint = () => {
    if (!stats.length) return null;
    return stats.reduce((prev, current) => 
      prev.count > current.count ? prev : current
    );
  };

  const renderChart = () => {
    switch (chartType) {
      case 'line':
        return <Line data={getChartData()} options={chartOptions} />;
      case 'doughnut':
        return <Doughnut data={getChartData()} options={chartOptions} />;
      default:
        return <Bar data={getChartData()} options={chartOptions} />;
    }
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          Statistics Dashboard
          <Tooltip title="View detailed API usage statistics and analytics">
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Monitor and analyze your API usage patterns and performance metrics.
        </Typography>
      </Box>

      {error && (
        <Alert 
          severity="error" 
          variant="outlined"
          sx={{ mb: 3 }}
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={fetchStatistics}
            >
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      )}

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <BarChartIcon sx={{ color: 'primary.main', mr: 1 }} />
                <Typography variant="h6" color="text.secondary">
                  Total API Calls
                </Typography>
              </Box>
              {loading ? (
                <Skeleton variant="text" width="60%" height={40} />
              ) : (
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {getTotalCalls().toLocaleString()}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TrendingUpIcon sx={{ color: 'primary.main', mr: 1 }} />
                <Typography variant="h6" color="text.secondary">
                  Most Used Endpoint
                </Typography>
              </Box>
              {loading ? (
                <Skeleton variant="text" width="80%" height={40} />
              ) : (
                <Typography variant="h6">
                  {getMostUsedEndpoint()?.endpoint || 'N/A'}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Paper sx={{ p: 3, mb: 4, borderRadius: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
            Usage Trends
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl size="small">
              <InputLabel>Chart Type</InputLabel>
              <Select
                value={chartType}
                label="Chart Type"
                onChange={(e) => setChartType(e.target.value as ChartType)}
                sx={{ minWidth: 120 }}
              >
                <MenuItem value="bar">Bar Chart</MenuItem>
                <MenuItem value="line">Line Chart</MenuItem>
                <MenuItem value="doughnut">Doughnut Chart</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title="Refresh data">
              <IconButton 
                onClick={fetchStatistics}
                disabled={refreshing}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {loading ? (
          <Box sx={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ height: 400 }}>
            {renderChart()}
          </Box>
        )}
      </Paper>

      <Paper sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium', mb: 3 }}>
          Detailed Statistics
        </Typography>
        <Grid container spacing={2}>
          {loading ? (
            Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 1 }} />
              </Grid>
            ))
          ) : (
            stats.map((stat) => (
              <Grid item xs={12} sm={6} md={4} key={stat.endpoint}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      {stat.endpoint}
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                      {stat.count.toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      API Calls
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default Statistics;
