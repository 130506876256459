import api, { endpoints } from './apiConfig';

export interface LoginRequest {
  username: string;
  password: string;
}

export interface User {
  id: number;
  username: string;
}

export interface LoginResponse {
  message: string;
  token: string;
  user: User;
}

export interface ErrorResponse {
  error: string;
  status: string;
}

class AuthService {
  private setAxiosToken(token: string | null) {
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete api.defaults.headers.common['Authorization'];
    }
  }

  async login(credentials: LoginRequest): Promise<LoginResponse> {
    const response = await api.post<LoginResponse>(endpoints.auth.login, credentials);
    const { token } = response.data;
    if (token) {
      this.setToken(token);
      this.setAxiosToken(token);
    }
    return response.data;
  }

  async logout(): Promise<void> {
    localStorage.removeItem('token');
    this.setAxiosToken(null);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  initializeAuth(): void {
    const token = this.getToken();
    if (token) {
      this.setAxiosToken(token);
    }
  }
}

export const authService = new AuthService();

// Initialize authentication on module load
authService.initializeAuth();
