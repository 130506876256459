import api, { endpoints } from './apiConfig';

export interface CattleTaggingResponse {
  // Add specific response fields based on your needs
  success: boolean;
  message: string;
}

export interface MuzzleDetectionResponse {
  message: string;
  muzzle_image: string;
}

export interface MuzzleMatchingResponse {
  message: string;
  similarity_score: number;
  processing_time: number;
}

export const cattleService = {
  async tagCattle(file: File, metadata?: any): Promise<CattleTaggingResponse> {
    const formData = new FormData();
    formData.append('file', file);
    if (metadata) {
      formData.append('metadata', JSON.stringify(metadata));
    }

    const response = await api.post<CattleTaggingResponse>(endpoints.cattle.tag, formData);
    return response.data;
  },

  async detectMuzzle(file: File): Promise<MuzzleDetectionResponse> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post<MuzzleDetectionResponse>(endpoints.muzzle.detect, formData);
    return response.data;
  },

  async matchMuzzle(file1: File, file2: File): Promise<MuzzleMatchingResponse> {
    const formData = new FormData();
    formData.append('file1', file1);
    formData.append('file2', file2);

    const response = await api.post<MuzzleMatchingResponse>(endpoints.muzzle.match, formData);
    return response.data;
  },

  async matchTwoImages(file1: File, file2: File): Promise<MuzzleMatchingResponse> {
    const formData = new FormData();
    formData.append('file1', file1);
    formData.append('file2', file2);

    const response = await api.post<MuzzleMatchingResponse>(endpoints.muzzle.matchTwoImages, formData);
    return response.data;
  },

  async getStats(): Promise<any> {
    const response = await api.get(endpoints.system.stats);
    return response.data;
  },
};
