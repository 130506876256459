import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://muzzlematcher.pixuate.com';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor for content type handling
api.interceptors.request.use(
  (config) => {
    // Don't set Content-Type for FormData (let browser set it with boundary)
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// API endpoints
export const endpoints = {
  auth: {
    login: '/api/v1/login',
  },
  cattle: {
    tag: '/api/v1/cattle-tagging',
  },
  muzzle: {
    detect: '/api/v1/detect-muzzle',
    match: '/api/v1/match-muzzle',
    matchTwoImages: '/api/v1/match-two-triton',
  },
  system: {
    stats: '/api/v1/stats',
  },
};

export default api;
