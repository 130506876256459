import React from 'react';
import { Box, Grid, Paper, Typography, Button, Card, CardContent, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CompareIcon from '@mui/icons-material/Compare';
import BarChartIcon from '@mui/icons-material/BarChart';
import CattleIcon from '@mui/icons-material/Pets';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface FeatureCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  path: string;
}

interface StatCardProps {
  title: string;
  value: string;
  icon: React.ReactNode;
  trend?: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon, trend }) => (
  <Card sx={{ height: '100%', bgcolor: 'background.paper' }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box sx={{ color: 'primary.main', mr: 1 }}>{icon}</Box>
        <Typography variant="h6" color="text.secondary">
          {title}
        </Typography>
      </Box>
      <Typography variant="h4" sx={{ mb: 1 }}>
        {value}
      </Typography>
      {trend && (
        <Typography variant="body2" color="success.main" sx={{ display: 'flex', alignItems: 'center' }}>
          <TrendingUpIcon sx={{ fontSize: 16, mr: 0.5 }} />
          {trend}
        </Typography>
      )}
    </CardContent>
  </Card>
);

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, icon, path }) => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        p: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: 6,
          '& .MuiButton-root': {
            bgcolor: 'primary.dark',
          },
        },
      }}
      onClick={() => navigate(path)}
    >
      <Box 
        sx={{ 
          mb: 2, 
          color: 'primary.main',
          p: 2,
          borderRadius: '50%',
          bgcolor: 'primary.light',
          opacity: 0.1,
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            bgcolor: 'primary.main',
            opacity: 0.2,
            zIndex: -1,
          }
        }}
      >
        {React.cloneElement(icon as React.ReactElement, { sx: { fontSize: 40 } })}
      </Box>
      <Typography variant="h6" gutterBottom fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3, flexGrow: 1 }}>
        {description}
      </Typography>
      <Button 
        variant="contained" 
        fullWidth
        sx={{
          borderRadius: 2,
          py: 1,
          textTransform: 'none',
          fontWeight: 'bold',
        }}
      >
        Get Started
      </Button>
    </Paper>
  );
};

const Dashboard: React.FC = () => {
  const features = [
    {
      title: 'Muzzle Detection',
      description: 'Upload and analyze cattle muzzle patterns with our advanced AI detection system.',
      icon: <PhotoCameraIcon />,
      path: '/muzzle-detection',
    },
    {
      title: 'Cattle Muzzle Match',
      description: 'Accurately identify and match cattle muzzle patterns.',
      icon: <CattleIcon />,
      path: '/cattle-muzzle-match',
    },
    {
      title: 'Muzzle Matching',
      description: 'Accurately identify with muzzle pattern recognition.',
      icon: <CompareIcon />,
      path: '/muzzle-matching',
    },
    {
      title: 'Statistics',
      description: 'Get detailed insights and analytics about your cattle management operations.',
      icon: <BarChartIcon />,
      path: '/statistics',
    },
  ];

  const stats = [
    {
      title: 'Total Cattle',
      value: '2,547',
      icon: <CattleIcon sx={{ fontSize: 24 }} />,
      trend: '+12% this month',
    },
    {
      title: 'Successful Matches',
      value: '1,834',
      icon: <CompareIcon sx={{ fontSize: 24 }} />,
      trend: '+8% this week',
    },
    {
      title: 'Recent Scans',
      value: '324',
      icon: <AccessTimeIcon sx={{ fontSize: 24 }} />,
      trend: 'Last 24 hours',
    },
  ];

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontWeight: 'bold',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Welcome to Muzzle Matcher
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Your comprehensive solution for cattle identification and management using AI-powered muzzle pattern recognition.
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        {stats.map((stat) => (
          <Grid item xs={12} sm={6} md={4} key={stat.title}>
            <StatCard {...stat} />
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        Quick Actions
      </Typography>

      <Grid container spacing={3}>
        {features.map((feature) => (
          <Grid item xs={12} sm={6} md={3} key={feature.title}>
            <FeatureCard {...feature} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboard;
